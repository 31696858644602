import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSquareFacebook,
    faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
    return (
        <footer className="w-full border-t border-solid border-customBorder bg-customGray flex flex-col items-center py-4">
            <div className="mx-auto my-1">
                <p className="font-light">© 2021 Immobilier sur mesure</p>
            </div>
            <div className="flex space-x-4">
                <a
                    href="https://facebook.com"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FontAwesomeIcon
                        icon={faSquareFacebook}
                        className="text-gray-700"
                    />
                </a>
                <a
                    href="https://instagram.com"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FontAwesomeIcon
                        icon={faInstagram}
                        className="text-gray-700"
                    />
                </a>
            </div>
        </footer>
    );
};

export default Footer;
