import React, { useState } from "react";
import Pictures from "../DetailsComponents/Pictures";
import BottomPictures from "../DetailsComponents/BottomPictures";
import Description from "../DetailsComponents/Description";
import StrongPoint from "../DetailsComponents/StrongPoint";
import img1 from "../../../assets/Image/Details4/Photo1.jpg";
import img2 from "../../../assets/Image/Details4/Photo2.jpg";
import img3 from "../../../assets/Image/Details4/Photo3.jpg";
import img4 from "../../../assets/Image/Details4/Photo4.jpg";
import img5 from "../../../assets/Image/Details4/Photo5.jpg";
import {
    faHouseMedical,
    faPersonSwimming,
    faTree,
} from "@fortawesome/free-solid-svg-icons";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const images = [img1, img2, img3, img4, img5];

const DetailsId4 = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    const handleImageClick = (index) => {
        setPhotoIndex(index);
        setIsOpen(true);
    };
    return (
        <div>
            <div className="px-48 xl:px-28 lg:px-24 md:px-14 sm:px-6">
                <Pictures
                    title="Maison 4 pièces"
                    images={images}
                    onImageClick={handleImageClick}
                />
                <BottomPictures place="Grand'Rue" state="Disponible" />
                <div className="my-20 flex justify-around items-center reveal-left lg:flex-wrap gap-8 md:flex-wrap gap-8 sm:flex-wrap gap-8">
                    <StrongPoint icon={faHouseMedical} text="État du bien" />
                    <StrongPoint icon={faPersonSwimming} text="Piscine" />
                    <StrongPoint icon={faTree} text="Grand Terrain" />
                </div>
                <Description
                    text="L'immobilier sur Mesure vous propose cette maison datant de 2010 de 145m² habitable située à Naintré à 3min en voiture des commerces.
                    Lumineuse et très bien disposée elle se compose d'une spacieuse pièce de vie ouvrant sur une belle terrasse, cuisine ouverte aménagée et équipée, une suite parentale, trois chambres avec rangements, une salle de bain, wc séparé.
                    Grand terrain de 2630m² sur lequel vous pourrez profiter d'une piscine sans aucun vis à vis.
                    Une cave et un grand garage complète ce bien rare sur le secteur"
                />
            </div>
            {isOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
                    <button
                        className="absolute top-4 right-4 text-white text-2xl"
                        onClick={() => setIsOpen(false)}
                    >
                        &times;
                    </button>
                    <div className="w-8/12 md:w-3/4 lg:w-3-4 sm:w-full">
                        <Carousel
                            selectedItem={photoIndex}
                            showThumbs={false}
                            showStatus={false}
                            autoPlay={true}
                            interval={4000}
                            infiniteLoop
                            useKeyboardArrows
                            onChange={(index) => setPhotoIndex(index)}
                        >
                            {images.map((img, index) => (
                                <div key={index}>
                                    <img
                                        src={img}
                                        alt={`Show ${index + 1}`}
                                        className="object-contain w-full"
                                    />
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DetailsId4;
