import React from "react";
import Review from "../../Review";

const Reviews = () => {
    return (
        <div className="px-48 my-20 flex flex-col xl:px-28 lg:px-24 md:px-16 sm:px-6">
            <div className="flex flex-col justify-start mr-auto w-2/3 mb-10 lg:w-full md:w-full sm:w-full">
                <h3 className="text-2xl mb-6 reveal-left">
                    Avis de nos clients
                </h3>
                <p className="font-light reveal-right">
                    Les avis de nos clients, c'est un peu comme le baromètre de
                    notre succès : ils témoignent de notre engagement à vous
                    offrir un service sur-mesure, efficace et toujours dans la
                    bonne humeur !<br />
                    Chez nous, pas de jargon compliqué, on vous accompagne dans
                    vos projets immobiliers avec professionnalisme et une touche
                    d’humour. Chaque témoignage que vous laissez est pour nous
                    une véritable médaille, une preuve que nous avons fait de
                    votre projet une réussite et, on l’espère, un moment
                    agréable. Qu’il s’agisse de vous avoir trouvé la maison de
                    vos rêves, un investissement parfait ou simplement de vous
                    avoir fait rire en chemin, votre satisfaction est notre plus
                    grande récompense.
                    <br />
                    Alors, si vous voulez entrer dans notre "Hall of Fame", vous
                    savez ce qu'il vous reste à faire...
                </p>
            </div>
            <div className="flex justify-around sm:flex-col">
                <div className="flex flex-col reveal-left">
                    <Review
                        avatar={"E"}
                        name={"Estelle"}
                        from={"Google"}
                        rate={"5"}
                        date={"Décembre 2024"}
                        feedback={
                            "François a été top pour la vente de mon bien (en seulement quelques semaines): réactif et toujours avec une touche de bonne humeur. Merci encore !"
                        }
                    />
                    <Review
                        avatar={"M"}
                        name={"Megane"}
                        from={"Google"}
                        rate={"5"}
                        date={"Novembre 2024"}
                        feedback={
                            "Très bonne expérience avec François, un agent immobilier à votre écoute. Il a su répondre à mes attentes et être réactif pour me trouver un appartement. Je le recommande vivement !"
                        }
                    />
                </div>
                <div className="flex flex-col reveal-right">
                    <Review
                        avatar={"M"}
                        name={"Marc"}
                        from={"Google"}
                        rate={"5"}
                        date={"Décembre 2024"}
                        feedback={
                            "Gestion de mon bien en location à Buxerolles d’une main de maître, avec efficacité et bonne humeur. Attention Francois est très drôle - juste dommage qu’il supporte les Girondins de Bordeaux, mais on lui pardonne !"
                        }
                    />
                    <Review
                        avatar={"W"}
                        name={"William"}
                        from={"Google"}
                        rate={"5"}
                        date={"Octobre 2024"}
                        feedback={
                            "François est un agent sérieux et compétent, très disponible et qui propose, à mon sens, des honoraires très justes. Bravo!"
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default Reviews;
