import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown } from "@fortawesome/free-solid-svg-icons";

const Argument = ({ title, explanation }) => {
    return (
        <div className="flex flex-col w-96 p-10 mb-5 lg:w-[300px] p-5 sm:w-full">
            <div className="flex items-center justify-around mb-5">
                <div className="rounded-full bg-customPink w-12 h-12 flex items-center justify-center mr-10">
                    <FontAwesomeIcon
                        icon={faCrown}
                        className="text-white text-xl"
                    />
                </div>
                <h5 className="mr-auto">{title}</h5>
            </div>
            <div className="font-light">{explanation}</div>
        </div>
    );
};

export default Argument;
