import React from "react";
import Diapo from "./AgenceComponents/Diapo";
import AgenceText from "./AgenceComponents/AgenceText";
import Bandeau from "../Bandeau";
import CurrentSale from "./AgenceComponents/CurrentSale";
import AlreadySale from "./AgenceComponents/AlreadySale";

const MainAgence = () => {
    return (
        <div>
            <Diapo />
            <AgenceText />
            <Bandeau />
            <CurrentSale />
            <AlreadySale />
        </div>
    );
};

export default MainAgence;
