import React from "react";

const Review = ({ avatar, name, from, rate, date, feedback }) => {
    return (
        <div className="flex flex-col w-96 p-5 m-5 cursor-default lg:w-64 md:w-56 sm:w-full">
            <div className="flex">
                <div className="rounded-full bg-customPink w-12 h-12 flex items-center justify-center">
                    <p className="font-bold text-white">{avatar}</p>
                </div>
                <div className="ml-3">
                    <p className="font-bold">{name}</p>
                    <p className="font-light">{from}</p>
                </div>
            </div>
            <div className="flex my-3">
                <p>{rate}⭐</p>
                <p className="font-bold ml-3">{date}</p>
            </div>
            <div>
                <p>{feedback}</p>
            </div>
        </div>
    );
};

export default Review;
