import React from "react";
import Argument from "../../Argument";
import styled from "styled-components";

const ContactButton = styled.a`
    background-color: #a81d6a;
    color: white;
    border-radius: 5px;
    padding: 10px 10px;
    margin: 0 auto;
    cursor: pointer;
    max-width: 189px;
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.01);
    }
`;

const ArgumentsChasseur = () => {
    return (
        <div className="px-48 my-20 flex flex-col xl:px-28 lg:px-24 md:px-14 sm:px-6">
            <h5 className="text-start text-xl mb-10">
                Pourquoi faire appel à un chasseur d'apppartement ?
            </h5>

            <div className="flex justify-around md:flex-col items-center sm:flex-col">
                <div className="flex flex-col reveal-left">
                    <Argument
                        title="Définition de vos critères"
                        explanation="Nous définissons ensemble vos attentes précises et vos besoins (type de bien, quartier, budget)."
                    />
                    <Argument
                        title="Visites et Sélection"
                        explanation="J’organise les visites et vous fais des retours détaillés, pour que vous ne visitiez que les biens correspondant à vos attentes."
                    />
                </div>
                <div className="flex flex-col reveal-right">
                    <Argument
                        title="Prospection active"
                        explanation="Je recherche pour vous les biens qui correspondent à vos critères en utilisant mon réseau local et mes partenariats exclusifs."
                    />
                    <Argument
                        title="Négociation et Suivi"
                        explanation="Je vous accompagne dans la négociation du prix, dans les calculs de rentabilité en cas d'investissement locatif et vous aide dans toutes les démarches administratives jusqu'à la remise des clés."
                    />
                </div>
            </div>
            <ContactButton href="francois@immobilier-surmesure.com">
                contactez nous
            </ContactButton>
        </div>
    );
};

export default ArgumentsChasseur;
