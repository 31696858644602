import React from "react";
import imgtest from "../../../assets/Image/testVente.jpg";
import styled from "styled-components";

const ContactButton = styled.button`
    background-color: #a81d6a;
    color: white;
    border-radius: 5px;
    padding: 10px 10px;
    margin: 40px auto 0 auto;
    cursor: pointer;
    max-width: 189px;
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.01);
    }
`;

const PresentationSale = () => {
    return (
        <div className="px-48 my-20 flex flex-col justify-center xl:px-28 lg:px-24 md:px-20 sm:px-6">
            <div className="w-full h-[400px]">
                <img
                    src={imgtest}
                    alt="test"
                    className="w-full h-full object-cover"
                />
            </div>
            <div className="my-2">
                <h3 className="text-2xl text-center my-8">
                    Agence sur mesure, spécialiste de Poitiers et ses alentours
                    ...
                </h3>
                <p className="font-light px-40 text-justify reveal-right xl:px-0 lg:px-0 md:px-0 sm:px-0">
                    Découvrez un processus de vente innovant et optimisé pour
                    vous accompagner à chaque étape de votre projet immobilier.
                    Nous vous offrons une estimation précise de votre bien avec
                    un compte rendu complet sous 48 heures, suivie de la
                    définition d'une stratégie de vente personnalisée pour
                    maximiser son attractivité. Grâce à une commercialisation
                    ciblée et un suivi rigoureux, nous sécurisons le meilleur
                    prix de vente dans des conditions transparentes. Enfin, nous
                    vous assistons lors des démarches notariales, de la promesse
                    de vente jusqu'à la signature finale, pour garantir une
                    transaction fluide et sécurisée. Confiez-nous votre projet
                    et vendez votre bien rapidement au meilleur prix.
                </p>
            </div>
            <ContactButton>contactez nous</ContactButton>
        </div>
    );
};

export default PresentationSale;
