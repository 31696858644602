import React from "react";

const Description = ({ text }) => {
    return (
        <div classname="w-full">
            <p className="mb-20 text-justify reveal-right">{text}</p>
        </div>
    );
};

export default Description;
