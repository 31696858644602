import React from "react";
import imgPresentation from "../../../assets/Image/testVente.jpg";

const PresentationChasseur = () => {
    return (
        <div className="px-48 my-10 flex flex-col justify-cente xl:px-28 lg:px-24 md:px-20 sm:px-6">
            <div className="w-full h-[400px]">
                <img
                    src={imgPresentation}
                    alt="test"
                    className="w-full h-full object-cover"
                />
            </div>
            <div className="my-2">
                <h3 className="text-2xl text-center my-8">
                    Agence sur mesure, votre chasseur de biens ...
                </h3>
                <p className="font-light px-40 text-justify reveal-bottom xl:px-0 lg:px-0 md:px-0 sm:px-0">
                    À la recherche d'un bien immobilier à Poitiers ou ses
                    environs ? Que ce soit pour une première résidence, un
                    pied-à-terre ou un investissement locatif, nous vous
                    accompagnons pour simplifier votre démarche, gagner du temps
                    et éviter le stress. Experts du marché local, nous vous
                    aidons à trouver la perle rare dans une ville où la demande
                    est forte, tout en vous offrant un service personnalisé et
                    de confiance.
                </p>
            </div>
        </div>
    );
};

export default PresentationChasseur;
