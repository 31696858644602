import React from "react";
import styled from "styled-components";
import poitiers from "../../../assets/Image/testVente.jpg";
import { ReactComponent as LogoDiapo } from "../../../assets/Icons/ISM_Diapo.svg";

const Background = styled.div`
    background: rgba(0, 0, 0, 0.5);
`;

const Diapo = () => {
    return (
        <div className="relative w-full h-[735px]">
            <img
                src={poitiers}
                alt="Paysage Poitiers"
                className="relative w-full h-full object-cover"
            />
            <Background className="absolute top-0 left-0 flex justify-center items-center w-full h-full">
                <div className="text-center text-white">
                    <h2 className="text-xl">AGENCE IMMOBILIÈRE</h2>
                    <LogoDiapo className="sm:w-[350px]" />
                </div>
            </Background>
        </div>
    );
};

export default Diapo;
