import React from "react";
import MainGestionLocative from "../components/GestionLocativeMain/MainGestionLocative";

const GestionLocative = () => {
    return (
        <div>
            <MainGestionLocative />
        </div>
    );
};

export default GestionLocative;
