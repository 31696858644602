import React from "react";
import MainVendre from "../components/VendreMain/MainVendre";
import Bandeau from "../components/Bandeau";
import Review from "../components/VendreMain/VendreComponents/Reviews";

const Vendre = () => {
    return (
        <div>
            <MainVendre />
            <Bandeau />
            <Review />
        </div>
    );
};

export default Vendre;
