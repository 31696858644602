import React from "react";
import MainChasseurAppartements from "../components/ChasseurAppartementMain/MainChasseurAppartements";

const ChasseurAppartement = () => {
    return (
        <div>
            <MainChasseurAppartements />
        </div>
    );
};

export default ChasseurAppartement;
