import React from "react";
import CurrentSales from "./AcheterLouerComponents/CurrentSales";

const MainAcheterLouer = () => {
    return (
        <div>
            <CurrentSales />
        </div>
    );
};

export default MainAcheterLouer;
