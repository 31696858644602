import React from "react";

const AlreadySale = () => {
    return (
        <div className="flex flex-col my-24 mx-auto px-48 border xl:px-12 lg:px-10 md:px-8 sm:px-7">
            <h3 className="text-2xl mb-5 reveal-left">Historique</h3>
            <div className="flex flex-wrap justify-start gap-4 ml-10 reveal-right sm:ml-0">
                <p className="font-light text-customGray2">
                    Aucun bien pour le moment ...
                </p>
            </div>
        </div>
    );
};

export default AlreadySale;
