import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../assets/Icons/ISM_Header.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { Link, NavLink } from "react-router-dom";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

const Estimation = styled.a`
    background-color: #a81d6a;
    border-radius: 5px;
    align-items: center;
    padding: 0.5rem 1rem;
    color: #ffffff;
    cursor: pointer;
`;

// const FilterContainer = styled.div`
//     position: relative;
//     display: none;
//     justify-content: space-between;
//     margin: 24px auto;
//     max-width: 75%;
//     height: 33%;
//     border: 1px solid #dddddd;
//     border-radius: 32px;
// `;

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <header className="w-full bg-white flex flex-col px-10 mb-8 relative">
            <div className="flex justify-between items-center pt-6 sm:flex-col sm:items-center sm:pt-12 md:flex-col lg:flex-col xl:flex-col">
                <div className="2xl:hidden flex items-center sm:absolute top-4 left-4 md:absolute top-4 left-4 lg:absolute top-4 left-4 xl:absolute top-4 left-4">
                    <button
                        onClick={toggleMenu}
                        className="text-gray-700 focus:outline-none"
                    >
                        <FontAwesomeIcon
                            icon={isOpen ? faTimes : faBars}
                            className="text-2xl"
                        />
                    </button>
                </div>
                <div>
                    <Link to="/">
                        <Logo
                            alt="Logo Immobilier sur mesure"
                            className="mb-2 sm:mb-5 sm:mt-5"
                        />
                    </Link>
                </div>
                <div className="flex justify-center items-center sm:hidden md:hidden lg:hidden xl:hidden">
                    <nav className="flex space-x-4">
                        <NavLink
                            to={"/"}
                            className={({ isActive }) =>
                                isActive
                                    ? "text-customPink"
                                    : "text-customGray2 hover:text-customBlack transition duration-200 ease-in-out"
                            }
                        >
                            L'agence
                        </NavLink>
                        <NavLink
                            to={"/acheter-louer"}
                            className={({ isActive }) =>
                                isActive
                                    ? "text-customPink"
                                    : "text-customGray2 hover:text-customBlack transition duration-200 ease-in-out"
                            }
                        >
                            Acheter / Louer
                        </NavLink>
                        <NavLink
                            to={"/vendre"}
                            className={({ isActive }) =>
                                isActive
                                    ? "text-customPink"
                                    : "text-customGray2 hover:text-customBlack transition duration-200 ease-in-out"
                            }
                        >
                            Vendre
                        </NavLink>
                        <NavLink
                            to={"/gestion-locative"}
                            className={({ isActive }) =>
                                isActive
                                    ? "text-customPink"
                                    : "text-customGray2 hover:text-customBlack transition duration-200 ease-in-out"
                            }
                        >
                            Gestion locative
                        </NavLink>
                        <NavLink
                            to={"/chasseur-appartement"}
                            className={({ isActive }) =>
                                isActive
                                    ? "text-customPink"
                                    : "text-customGray2 hover:text-customBlack transition duration-200 ease-in-out"
                            }
                        >
                            Chasseur d'appartement
                        </NavLink>
                    </nav>
                </div>
                <div className="flex justify-between items-center">
                    <a href="mailto:francois@immobilier-surmesure.com">
                        <FontAwesomeIcon icon={faEnvelope} />
                    </a>
                    <div className="flex mx-2">
                        <p>06 47 66 14 50</p>
                    </div>
                    <Estimation
                        className="sm:hidden md:hidden lg:hidden xl:hidden"
                        href="mailto:francois@immobilier-surmesure.com"
                    >
                        Estimation
                    </Estimation>
                </div>
            </div>
            {isOpen && (
                <div
                    className={`${
                        isOpen ? "opacity-100" : "opacity-0"
                    } overflow-hidden duration-500 ease-in-out flex flex-col items-center mt-4 space-y-4 2xl:hidden`}
                >
                    <NavLink
                        to="/"
                        className={({ isActive }) =>
                            isActive
                                ? "text-customPink"
                                : "text-customGray2 hover:text-customBlack transition duration-200 ease-in-out"
                        }
                        onClick={toggleMenu}
                    >
                        L'agence
                    </NavLink>
                    <NavLink
                        to="/acheter-louer"
                        className={({ isActive }) =>
                            isActive
                                ? "text-customPink"
                                : "text-customGray2 hover:text-customBlack transition duration-200 ease-in-out"
                        }
                        onClick={toggleMenu}
                    >
                        Acheter / Louer
                    </NavLink>
                    <NavLink
                        to="/vendre"
                        className={({ isActive }) =>
                            isActive
                                ? "text-customPink"
                                : "text-customGray2 hover:text-customBlack transition duration-200 ease-in-out"
                        }
                        onClick={toggleMenu}
                    >
                        Vendre
                    </NavLink>
                    <NavLink
                        to="/gestion-locative"
                        className={({ isActive }) =>
                            isActive
                                ? "text-customPink"
                                : "text-customGray2 hover:text-customBlack transition duration-200 ease-in-out"
                        }
                        onClick={toggleMenu}
                    >
                        Gestion locative
                    </NavLink>
                    <NavLink
                        to="/chasseur-appartement"
                        className={({ isActive }) =>
                            isActive
                                ? "text-customPink"
                                : "text-customGray2 hover:text-customBlack transition duration-200 ease-in-out"
                        }
                        onClick={toggleMenu}
                    >
                        Chasseur d'appartement
                    </NavLink>
                </div>
            )}
            {/* <FilterContainer></FilterContainer> */}
        </header>
    );
};

export default Header;
