import React from "react";
import MainAgence from "../components/AgenceMain/MainAgence";

const Agence = () => {
    return (
        <div>
            <MainAgence />
        </div>
    );
};

export default Agence;
