import React from "react";
import styled from "styled-components";
import Francois from "../../../assets/Image/Francois.jpg";

const ContactButton = styled.a`
    background-color: #a81d6a;
    color: white;
    border-radius: 5px;
    padding: 10px 10px;
    margin: 25px 0;
    cursor: pointer;
    width: 80%;
    transition: transform 0.3s ease;
    text-align: center;

    &:hover {
        transform: scale(1.01);
    }
`;

const BottomPictures = ({ place, state }) => {
    return (
        <div className="flex w-full justify-between my-6 sm:flex-col items-start">
            <div className="flex flex-col justify-around">
                <h5>{place}</h5>
                <div className="flex sm:my-4">
                    <img
                        className="object-cover w-[44px] h-[44px] rounded-full"
                        src={Francois}
                        alt="Placeholder"
                    />
                    <div className="flex flex-col ml-2 w-full">
                        <p classname="font-light">
                            En vente par{" "}
                            <span className="font-bold">François Boucher</span>
                        </p>
                        <p className="font-light">Gérant</p>
                    </div>
                </div>
            </div>
            <div className="w-1/3 flex flex-col items-center justify-center border-[1px] border-solid border-customBorder rounded-xl sm:w-full mt-4">
                <h5 className="text-2xl mt-4">{state}</h5>
                <ContactButton href="mailto:francois@immobilier-surmesure.com">
                    Nous contacter
                </ContactButton>
            </div>
        </div>
    );
};

export default BottomPictures;
