import React from "react";
import styled from "styled-components";

const StyledCard = styled.div`
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.01);
    }
`;

const Card = ({ onClick, status, title, description, adress, img }) => {
    return (
        <StyledCard
            className="flex flex-col border relative cursor-pointer flex-1 max-w-[330px] xl:max-w-[270px] lg:max-w-[300px] md:max-w-[250px] sm:w-full sm:mx-auto"
            onClick={onClick}
        >
            <div className="w-[330px] h-[330px] overflow-hidden xl:max-w-[270px] xl:max-h-[270px] lg:max-w-[300px] lg:max-h-[300px] md:max-w-[250px] md:max-h-[250px] sm:w-full sm:h-full">
                <img
                    src={img}
                    alt="Présentation"
                    className="w-full h-full z-20 object-cover"
                />
            </div>
            <div className="absolute rounded-full bg-customGray p-2 top-2 right-2 z-10">
                <p className="md:text-xs">{status}</p>
            </div>
            <div className="my-2 ml-2">
                <h5 className="font-medium">{title}</h5>
                <p className="font-light">{description}</p>
                <h5 className="font-medium">{adress}</h5>
            </div>
        </StyledCard>
    );
};

export default Card;
