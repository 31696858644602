import React from "react";
import styled from "styled-components";

const ContactButton = styled.a`
    background-color: #a81d6a;
    color: white;
    border-radius: 5px;
    padding: 10px 10px;
    margin: 40px auto;
    cursor: pointer;
    max-width: 189px;
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.01);
    }
`;

const AgenceText = () => {
    return (
        <div className="flex flex-col items-center px-48 my-20 xl:px-28 lg:px-24 md:px-20 sm:px-6">
            <h1 className="text-3xl reveal-top">L'immobilier sur Mesure</h1>
            <ContactButton href="mailto:francois@immobilier-surmesure.com">
                contactez votre agence
            </ContactButton>
            <h2 className="font-light text-justify reveal-bottom">
                Agence immobilière indépendante, jeune et dynamique, fruit d'un
                mariage parfait entre agence traditionnelle et outils digitaux
                tout en pratiquant une politique d'honoraires au plus juste et
                en gardant une haute qualité de service. Notre volonté est de
                vous proposer un parcours client pertinent et efficace dans la
                vente, la location, la gestion, la recherche d'un bien
                immobilier grâce à notre expertise et nos outils. L'optimisation
                de la rentabilité, la sécurisation du financement ainsi que la
                satisfaction de nos clients sont nos priorités pour la
                réalisation et la réussite de vos projets immobiliers.
            </h2>
        </div>
    );
};

export default AgenceText;
