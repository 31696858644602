import React from "react";
import PresentationChasseur from "./ChasseurAppartementComponents/PresentationChasseur";
import ArgumentChasseur from "./ChasseurAppartementComponents/ArgumentsChasseur";

const MainChasseurAppartements = () => {
    return (
        <div>
            <PresentationChasseur />
            <ArgumentChasseur />
        </div>
    );
};

export default MainChasseurAppartements;
