import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const Bandeau = () => {
    return (
        <div className="w-full py-10 px-36 bg-customNightBlue flex flex-col xl:px-10 lg:px-8 md:px-6 sm:px-4">
            <h4 className="text-white text-3xl font-bold mb-10 xl:text-2xl lg:text-xl md:text-2xl sm:text-2xl">
                Un barème d'honoraire simple et au plus juste ...
            </h4>

            <div className="flex justify-around w-full lg:justify-between md:flex-col items-center sm:flex-col items-center">
                <div className="flex items-center reveal-left">
                    <h5 className="text-customPink text-2xl font-bold xl:text-xl lg:text-lg sm:text-xl">
                        Entre 0 et 300.000€
                    </h5>
                    <FontAwesomeIcon
                        icon={faArrowRight}
                        className="text-white text-xl mx-4 xl:text-lg lg:text-base sm:text-lg"
                    />
                    <h5 className="text-customPink text-2xl font-bold xl:text-xl lg:text-lg sm:text-xl">
                        5%
                    </h5>
                </div>
                <div className="flex items-center my-10 reveal-bottom md:mb-0 sm:mb-0">
                    <h5 className="text-customPink text-2xl font-bold xl:text-xl lg:text-lg sm:text-xl">
                        Entre 300.000 et 500.000€
                    </h5>
                    <FontAwesomeIcon
                        icon={faArrowRight}
                        className="text-white text-xl mx-4 xl:text-lg lg:text-base sm:text-lg"
                    />
                    <h5 className="text-customPink text-2xl font-bold xl:text-xl lg:text-lg sm:text-xl">
                        4%
                    </h5>
                </div>
                <div className="flex items-center my-10 reveal-right md:mb-0 sm:mb-0">
                    <h5 className="text-customPink text-2xl font-bold xl:text-xl lg:text-lg sm:text-xl">
                        + de 500.000€
                    </h5>
                    <FontAwesomeIcon
                        icon={faArrowRight}
                        className="text-white text-xl mx-4 xl:text-lg lg:text-base sm:text-lg"
                    />
                    <h5 className="text-customPink text-2xl font-bold xl:text-xl lg:text-lg sm:text-xl">
                        3%
                    </h5>
                </div>
            </div>
        </div>
    );
};

export default Bandeau;
