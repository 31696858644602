import React from "react";
import PresentationSale from "./VendreComponents/PresentationSale";

const MainVendre = () => {
    return (
        <div>
            <PresentationSale />
        </div>
    );
};

export default MainVendre;
