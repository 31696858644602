import React, { useState } from "react";
import Pictures from "../DetailsComponents/Pictures";
import BottomPictures from "../DetailsComponents/BottomPictures";
import Description from "../DetailsComponents/Description";
import StrongPoint from "../DetailsComponents/StrongPoint";
import img1 from "../../../assets/Image/Details3/Photo1.jpg";
import img2 from "../../../assets/Image/Details3/Photo2.jpg";
import img3 from "../../../assets/Image/Details3/Photo3.jpg";
import img4 from "../../../assets/Image/Details3/Photo4.jpg";
import img5 from "../../../assets/Image/Details3/Photo5.jpg";
import {
    faHouseMedical,
    faLocation,
    faHouse,
} from "@fortawesome/free-solid-svg-icons";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const images = [img1, img2, img3, img4, img5];

const DetailsId3 = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    const handleImageClick = (index) => {
        setPhotoIndex(index);
        setIsOpen(true);
    };
    return (
        <div>
            <div className="px-48 xl:px-28 lg:px-24 md:px-14 sm:px-6">
                <Pictures
                    title="Maison 7 pièces centre ville poitiers"
                    images={images}
                    onImageClick={handleImageClick}
                />
                <BottomPictures place="Grand'Rue" state="Disponible" />
                <div className="my-20 flex justify-around items-center reveal-left lg:flex-wrap gap-8 md:flex-wrap gap-8 sm:flex-wrap gap-8">
                    <StrongPoint icon={faHouseMedical} text="État du bien" />
                    <StrongPoint icon={faLocation} text="Localisation" />
                    <StrongPoint icon={faHouse} text="Maison d'architecte" />
                </div>
                <Description
                    text="L'Immobilier sur Mesure vous propose cette charmante maison d'architecte de 200m²  en bon état située dans le bas de la Grand Rue.
                    Calme et très bien disposée elle se compose en rez de chaussée d'une spacieuse cuisine entièrement aménagée et équipée ouvrant sur une terrasse d'environ 20m². Par cette terrasse, accès à une belle chambre avec sa salle d'eau privative. Une autre salle d'eau complète le rez-de-chaussée avec WC.
                    Le 1er étage se compose d'un beau séjour, de deux chambres avec rangements et bureau.
                    Le 2ème étage se compose de deux nouvelles chambres avec rangements et bureau, d'une salle d'eau et d'un WC.
                    Le dernier étage se compose d'une belle suite parentale avec salle d'eau.
                    L'ensemble de la maison est actuellement loué en colocation (350 euros HC par chambre soit 2100 euros HC/mois)."
                />
            </div>
            {isOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
                    <button
                        className="absolute top-4 right-4 text-white text-2xl"
                        onClick={() => setIsOpen(false)}
                    >
                        &times;
                    </button>
                    <div className="w-8/12 md:w-3/4 lg:w-3-4 sm:w-full">
                        <Carousel
                            selectedItem={photoIndex}
                            showThumbs={false}
                            showStatus={false}
                            autoPlay={true}
                            interval={4000}
                            infiniteLoop
                            useKeyboardArrows
                            onChange={(index) => setPhotoIndex(index)}
                        >
                            {images.map((img, index) => (
                                <div key={index}>
                                    <img
                                        src={img}
                                        alt={`Show ${index + 1}`}
                                        className="object-contain w-full"
                                    />
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DetailsId3;
