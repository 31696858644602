import React from "react";
import imgtest from "../../../assets/Image/testVente.jpg";
import styled from "styled-components";

const ContactButton = styled.a`
    background-color: #a81d6a;
    color: white;
    border-radius: 5px;
    padding: 10px 10px;
    margin: 20px auto;
    cursor: pointer;
    max-width: 189px;
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.01);
    }
`;

const PresentationLocative = () => {
    return (
        <div className="px-48 my-10 flex flex-col justify-center xl:px-28 lg:px-24 md:px-20 sm:px-6">
            <div className="w-full h-[400px]">
                <img
                    src={imgtest}
                    alt="test"
                    className="w-full h-full object-cover"
                />
            </div>
            <div className="my-2">
                <h3 className="text-2xl text-center my-8">
                    Agence sur mesure, votre spécialiste de la gestion Locative
                    ...
                </h3>
                <p className="font-light px-40 text-justify reveal-right xl:px-0 lg:px-0 md:px-0 sm:px-0">
                    L'Immobilier sur Mesure s'engage à louer votre logement au
                    meilleur prix en alliant écoute, rigueur et réactivité.
                    Profitez de frais d'agence à moindre coût et d'un
                    accompagnement pour la location et la gestion de votre bien
                    ainsi que de notre réseau de professionnels (architectes,
                    entrepreneurs travaux, assureurs, caisse de garantie de
                    loyer impayés), partenaires obligatoires à la réussite de
                    votre projet. Vous êtes client ? Nous nous occupons de tout
                    : commercialisation de votre bien, photos, visites,
                    sélections des dossiers (avec votre accord), état des lieux,
                    gestion administrative, comptable, gestion des réparations
                    pendant toute la durée de vie du bail, état des lieux de
                    sortie. Nous nous engageons à vous verser les loyers au plus
                    tard le 5 de chaque mois.
                </p>
            </div>
            <ContactButton href="mailto:francois@immobilier-surmesure.com">
                contactez nous
            </ContactButton>
        </div>
    );
};

export default PresentationLocative;
